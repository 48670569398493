.user-block {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(95, 107, 129, .102);
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .078);
  //  background-color: rgba(0, 0, 0, 0.02);
  background-color: #FFF;

  .user-block-header {
    border-radius: 4px 4px 0 0;
    padding: 5px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
    border-bottom: 1px solid #E6E6E6;
    background-color: #f8f9fc;

    h3 {
      @include fontInter(16px, #000, 500);
      margin-bottom: unset;
    }


    .refresh-icon {
      cursor: pointer;

      &.loading {}
    }

    &.dark-header {
      background-color: rgba(95, 107, 129, .102);
      color: #000;

      h3 {
        @include fontInter(16px, #000, 500);
      }

      .link {
        color: #000 !important;
      }
    }

  }

  .user-block-content {
    padding: 16px;


    &.secondary {
      padding:18px;
      background-color: #EFEFEF;
    }
    .user-block-content-subtitle {
      @include fontInter(12px, #6B6B6B, 500);
    }

    .text-line {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        @include fontInter(14px, #000, 400);

      }

      &.secondary {
        p {
          color: #6B6B6B;
        }

        .pi {
          color: #6B6B6B;
        }
      }

      &.error {
        p {
          color: #de5050;
        }

        .pi {
          color: #de5050;
        }
      }
    }

    table {
      tbody {
        td {
          padding: 5px 10px;
          line-height: 140%;

          &:not(.empty) {
            &:first-of-type {
              padding-right: 30px;
            }
          }

          .small-icon {
            font-size: 13px;
          }

          &.label {
            @include fontInter(13px, #6B6B6B, 500);
          }

          &.value {
            @include fontInter(13px, #000, 500);

          }


        }
      }
    }

    .entities {
      padding-top: 8px;
      padding-bottom: 8px;

      .entity-link {
        padding: 8px 16px;
        background-color: #EFEFEF;
        border-radius: 4px;
        @include fontInter(24px, #000, 600);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .pi-arrow-right {}
      }

      &.multi {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .entity-wrapper {
          &:not(:last-of-type) {
            padding-bottom: 24px;
            border-bottom: 1px solid #DDD;
          }
        }
      }
    }
  }
}