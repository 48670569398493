p-tableHeaderCheckbox, p-tableCheckbox {
    .p-checkbox {
        .p-checkbox-box {
            width: 16px;
            height: 16px;
            border-radius: 2px;
            border: 1px solid #DCDCDC;
            outline: unset;
            box-shadow: unset;
         //   background-color: #FFF;

         &.p-highlight {
            background: #4252A3;
            border:unset;
            outline: unset;
            box-shadow: unset;
         }

         .p-icon {
            width: 12px;
            height: 12px;
         }
        }
    }
}

.checkbox-wrapper {
    display:flex;
    align-items: center;
    justify-content: flex-start;

    &.normal-input-height {
        height: 38px;
    }
    &.label-is-right {
        label {
            margin-left: 8px;
        }
    }

    &.label-is-left {
        label {
            margin-right: 8px;
        }
    }

    &.aligned-end {
        justify-content: flex-end;
    }

    label {
        @include fontInter(12px, #000, 500); 
        cursor: pointer;

        .count {
            color:#929292;
          }
    }

    p-checkbox {
        cursor: pointer;
   
            .p-checkbox {
                .p-checkbox-box {
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                    border: 1px solid #DCDCDC;
                    outline: unset;
                    box-shadow: unset;
                 //   background-color: #FFF;

                 &.p-highlight {
                    background: #4252A3;
                    border:unset;
                    outline: unset;
                    box-shadow: unset;
                 }

                 .p-icon {
                    width: 12px;
                    height: 12px;
                 }
                }
            }
            label {
                @include fontInter(12px, #000, 500); 
                cursor: pointer;
            }
        
    }
}

