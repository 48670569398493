.textarea-wrapper {
    width: 100%;

    display: flex;
    align-items: center;
    background: #EFEFEF;
    border: 1px solid transparent;
    border-radius: 4px;
    position: relative;
    margin-bottom: unset;

    &.mb-16 {
        margin-bottom:16px;
    }
    label {
        @include fontInter(12px, #787878, 500);  
    }

    &.borderless {
        textarea {
            border:unset;
        }
    }


    &:hover {
        border: 1px solid #1152F6 !important;
        box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
    }
    &.focused {
        border: 1px solid #1152F6 !important;
        box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
    }

    .suffix-icons {
        padding-right: 8px;
        height: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        overflow: unset;
        .clickable-action {
          cursor: pointer;
        }
      }

    textarea {
        border:1px solid transparent;
        box-shadow: unset;
        width: 100%;
        border-radius: 4px;
        padding-left:8px;
        padding-right: 8px;
        padding-top:13px;
        border-radius: 4px;
        height: 96px;
        outline:unset;
      //  min-width: 100%;
        @include fontInter(12px, #000, 500);  
        &::placeholder {
            @include fontInter(12px, #787878, 500);  
        }

        &.ng-untouched {
            border:1px solid transparent;
        }
        &.ng-invalid {
            &:not(.ng-untouched) {
                border:1px solid #FF1717; 
            }  
        }
    }



    &.primary {
        textarea {
            background: #EFEFEF;
        }
    }

    
    &.secondary {
        background-color: #FFF;
        textarea {
            background-color: #FFF;
        }

        &.dark-borders {
            border:1px solid #000;
            border-radius: 4px;
            textarea{
                background-color: #FFF;
            }

          }
      }


}