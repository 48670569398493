

.p-dropdown {
  &.p-focus {
    border: unset;
    outline: unset;
    box-shadow: unset;
  }


  // Clear dropdown button
timesicon {
  background: #71717a;
  position: absolute;
  right: -6px;
  top: -6px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}

svg {
&.p-dropdown-clear-icon {
  color: #FFF;
  border-radius: 50%;
  width: 10px !important;
  height: 10px !important;
  margin-top: unset;
  top:unset;
  right: unset;
  position: unset;
}
}

// Clear dropdown btn end

.p-dropdown-header {
  background-color: #FFF;
  border-bottom: 1px solid #DCDCDC;
  padding: 0.5rem;
  input {
    width: 100%;
    border-radius: 4px;
    background: #EFEFEF;
    height: 32px;
    @include fontInter(12px, #000, 500);
    border: unset;


    &::placeholder {
      @include fontInter(12px, #6B6B6B, 500);

    }
  }
  }

  .p-dropdown-empty-message {
    @include fontInter(12px, #000, 500);
  }
}



.inline-label-control {
    display: flex;
    align-items: center;
    background: #EFEFEF;
    border-radius: 4px;
    height: 40px;
    position: relative;
    margin-bottom:16px;
    position:relative;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid #1152F6 !important;
      box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
    }

    &.focused {
      border: 1px solid #1152F6 !important;
      box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
    }

    &.filled {
      border: 1px solid #1152F6 !important;
      box-shadow: 0px 0px 8px 0px rgba(27, 105, 223, 0.25) !important;
      background-color: #EBEDF5 !important;
  
  
        p-dropdown .p-dropdown {
          background-color: #EBEDF5 !important;
        }
      
    }

    &.disabled {
      opacity: 0.4;
      pointer-events: none;
    }

    &.no-label {
      padding-left:12px;
    }
    label {
      width: 40%;
      min-width: 40%;


      @include media-breakpoint-down(sm){
        width: 40%;
        min-width: 40%;
      }
      @include fontInter(12px, #6B6B6B, 500);
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 8px;

    }


    p-dropdown {
      display: flex;
      width: 100%;
      height: 100%;
      position: unset;
      overflow: hidden;
      text-overflow: ellipsis;

        .p-dropdown {
          width: 100%;
          height: 100%;
          border: unset;
          position: unset;
        }
  
        .p-dropdown-label {
          padding: unset;
          display: flex;
          align-items: center;
          @include fontInter(12px, #000, 500);
          white-space: break-spaces;
        }
  
        .p-dropdown-item {
          @include fontInter(12px, #000, 500);
          padding: unset;
          min-height: 36px;
          padding-top:8px;
          padding-bottom:8px;
          padding-left: 12px;
          display: flex;
          align-items: center;
          white-space: normal;
          word-break: break-word;
          .count {
            color:#929292;
          }
          &.p-highlight {
            background-color: unset;
            color:#DE1A1D;
          }
        }
  
        .p-dropdown-trigger {
          color: #000;
          width: 14px;
          margin-right: 12px;
          svg {
            width: 12px;
          }
        }
  
        .p-overlay {
          left: 0 !important;
          width: 100% !important;
        }
     
    }
  
  
    &.sm {
      height: 36px;
    }
    
    &.primary {
      background: #EFEFEF;
  
      label {
        @include fontInter(12px, #6B6B6B, 500);
      }
  
      p-dropdown {
        .p-dropdown {
          background: #EFEFEF;
        }
  
        .p-dropdown-label {
          @include fontInter(12px, #000, 500);
        }
  
        .p-dropdown-trigger {
          color: #000;
        }
      }
  
      
    }
    
    &.outline {
      background-color: #FFF;
      border:1px solid #EFEFEF;
      label {
        background-color: transparent;
      }

      p-dropdown {
        .p-dropdown {
          background: #FFF;
        }
      }
    }


    &.secondary {
      background-color: #FFF;
      border:1px solid rgba(0,0,0,.125);
      label {
        @include fontInter(12px, #6B6B6B, 500);
      }
  
      p-dropdown {
        .p-dropdown {
          background: #FFF;
        }
  
        .p-dropdown-label {
          @include fontInter(12px, #000, 500);
        }
  
        .p-dropdown-trigger {
          color: #000;
        }
      }
    }

    &.secondary {
      .inline-label-text-control {
        background-color: #FFF;
      }
  
      &.dark-borders {
        .inline-label-text-control {
          border:1px solid #000;
          border-radius: 4px;
        }
      }
      
    }
  
  }