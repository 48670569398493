.no-styles-btn {
    background:unset;
    border:unset;
    box-shadow: unset;
    text-align: left;
    padding:unset;
}

.c-button {
    border-radius: 4px;
    height: 40px;
    background: #404040;
    @include fontInter(12px, #FFF, 700);
    text-transform: uppercase;
    display:flex;
    align-items: center;
    justify-content: center;
    padding-left:24px;
    padding-right: 24px;
    border:unset;
    box-shadow: unset;
    white-space: nowrap;
    cursor: pointer;

    &:disabled {
        opacity: 0.4;
    }
    &.xs {
        height: 28px;
        text-transform: unset;
        font-weight: 500 !important;
    }
    &.small {
       height: 36px; 
    }
    &.large {
        height: 48px;
    }


    &.icon-only {
        padding: 0.5rem;
        border-radius: 4px;
        background-color: transparent;

        &.md {
            width: 32px;
            height: 32px;
          
        }

        &.rounded {
            border-radius: 50%;
        }
        .inner-icon {

        }
    }
    
   &.static-icon-only {
        padding:unset;
        width: 64px;
        min-width: 64px;
        height: 40px;
   }


    &.w-100 {
        width: 100%;
        padding-left:unset;
        padding-right: unset;
    }
    &.primary {
        background: #090909;
        @include fontInter(12px, #FFF, 700);
    }

    &.secondary {
        background: #4252A3;
        @include fontInter(12px, #FFF, 700);
    }

    &.tertiary {
        background: #EFEFEF;
        @include fontInter(12px, #000, 700); 
    }

    &.light-green {
        background-color: #3f9c7a;
        @include fontInter(12px, #FFF, 700);
    }

    &.light-red {
        background: #de5050;
        @include fontInter(12px, #FFF, 700); 
    }


    &.facebook {
        background: #3667B8;
        @include fontInter(12px, #FFF, 700);
    }

    &.google {
        background: #DD3A3A;
        @include fontInter(12px, #FFF, 700);
    }

    &.light {
        background: #FFF;
        border: 1px solid #323223;
        @include fontInter(12px, #323232, 700);
    }

    &.green-outline {
        background: #FFF;
        border: 1px solid #3f9c7a;
        @include fontInter(12px, #3f9c7a, 700);
    }

    &.red-outline {
        background: #FFF;
        border: 1px solid #de5050;
        @include fontInter(12px, #de5050, 700);
    }

    &.dark {
        background: #353535;
    }

    &.dark-blue {
        background: #202D3E;
    }

    &.dark-red {
        background:#DC2012;
    }

    &.light-blue {
        background:#275A80;
    }

    &.yellow {
        background: #F69808;
    }

    &.green {
        background-color: #3f9c7a;
    }
}