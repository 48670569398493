.radio-control-wrapper {
    p-radiobutton {

        .p-radiobutton {


            .p-radiobutton-box {
                width: 16px;
                height: 16px;
                background-color: #E7E7E7;
                border:1px solid #E7E7E7;
                
            }
            &:not(.p-radiobutton-disabled){
                .p-radiobutton-box {

                    &.p-focus {
    
                        box-shadow: 0 0 0 0px #ffffff, 0 0 0 2px #E7E7E7, 0 1px 2px 0 rgba(0, 0, 0, 0);
                      
                    }
                  
    
                    
                }
             
            }

        }

        .p-radiobutton-focused {
            .p-radiobutton-box {
                width: 16px;
                height: 16px;
                
            }
            &:not(.p-radiobutton-disabled){
                .p-radiobutton-box {

                    &.p-focus {
    
                        box-shadow: 0 0 0 0px #ffffff, 0 0 0 2px #4252A3, 0 1px 2px 0 rgba(0, 0, 0, 0);
                      
                    }
                  
    
                    
                }
             
            }
        }

        .p-radiobutton-checked {
            .p-radiobutton-icon {
                background-color: #4252A3;
                width: 8px;
                height: 8px;
            }

   
            &:not(.p-radiobutton-disabled){
                .p-radiobutton-box {
                    background-color: transparent;
                    border:unset;
                
    
                        box-shadow: 0 0 0 0px #ffffff, 0 0 0 2px #4252A3, 0 1px 2px 0 rgba(0, 0, 0, 0);
                          
                    
                }
             
            }
        }
    }

    label {
        cursor: pointer;
        @include fontInter(12px, #000, 500);
    }
}