.standart-p-menu {
  .p-submenu-header {
    @include fontInter(12px, #6B6B6B, 500);
  }

  li {

    .p-menuitem-content {
      @include fontInter(14px, #000, 500);

      &:hover {
        background-color: #EFEFEF;
      }


      .pi {
        color: #000;
      }


    }
  }
}



.user-p-menu {

  .p-submenu-header {
    @include fontInter(12px, #6B6B6B, 500);
  }

  li {

    .p-menuitem-content {
      @include fontInter(14px, #000, 500);

      &:hover {
        background-color: #EFEFEF;
      }


      .pi {
        color: #000;
      }


    }
  }
}
