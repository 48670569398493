.switch-control-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom:16px;
  label {
    @include fontInter(12px, #000, 500);
  }

  p-inputSwitch {
    .p-inputswitch .p-inputswitch-slider {
       // background-color: #EBEDF5;
    }
    .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background:#4252A3; 
    }

    
    .p-inputswitch {


      .p-inputswitch-slider {

      }
      .p-inputswitch-slider:before {

      }
    }

  }
}
